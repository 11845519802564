import { QueryClient } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { version } from "../../package.json";

const cacheTime = 1000 * 60 * 60 * 24 * 7; // 7 days

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      cacheTime,
    },
  },
});

// persistQueryClient({
//   queryClient,
//   buster: version,
//   maxAge: cacheTime,
//   persistor: createWebStoragePersistor({
//     storage: globalThis.app
//       ? globalThis.localStorage
//       : globalThis.sessionStorage,
//   }),
// });

export default queryClient;
