import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Json: any;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumReviewExportStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReviewExportStatus>;
};

export type EnumReviewExportStatusFilter = {
  equals?: InputMaybe<ReviewExportStatus>;
  in?: InputMaybe<Array<ReviewExportStatus>>;
  not?: InputMaybe<NestedEnumReviewExportStatusFilter>;
  notIn?: InputMaybe<Array<ReviewExportStatus>>;
};

export type EnumReviewRequestStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReviewRequestStatus>;
};

export type EnumReviewRequestStatusFilter = {
  equals?: InputMaybe<ReviewRequestStatus>;
  in?: InputMaybe<Array<ReviewRequestStatus>>;
  not?: InputMaybe<NestedEnumReviewRequestStatusFilter>;
  notIn?: InputMaybe<Array<ReviewRequestStatus>>;
};

export type EnumReviewStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReviewStatus>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonNullableFilter = {
  equals?: InputMaybe<Scalars['Json']>;
  not?: InputMaybe<Scalars['Json']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneReview: Review;
  createOneReviewExport: ReviewExport;
  createOneReviewRequest: ReviewRequest;
  createOneShare: Share;
  deleteOneReview?: Maybe<Review>;
  updateOneReview?: Maybe<Review>;
  updateOneReviewExport?: Maybe<ReviewExport>;
  updateOneReviewRequest?: Maybe<ReviewRequest>;
  updateOneShare?: Maybe<Share>;
  upsertOneReview: Review;
  upsertOneShare: Share;
};


export type MutationCreateOneReviewArgs = {
  data: ReviewCreateInput;
};


export type MutationCreateOneReviewExportArgs = {
  data: ReviewExportCreateInput;
};


export type MutationCreateOneReviewRequestArgs = {
  data: ReviewRequestCreateInput;
};


export type MutationCreateOneShareArgs = {
  data: ShareCreateInput;
};


export type MutationDeleteOneReviewArgs = {
  where: ReviewWhereUniqueInput;
};


export type MutationUpdateOneReviewArgs = {
  data: ReviewUpdateInput;
  where: ReviewWhereUniqueInput;
};


export type MutationUpdateOneReviewExportArgs = {
  data: ReviewExportUpdateInput;
  where: ReviewExportWhereUniqueInput;
};


export type MutationUpdateOneReviewRequestArgs = {
  data: ReviewRequestUpdateInput;
  where: ReviewRequestWhereUniqueInput;
};


export type MutationUpdateOneShareArgs = {
  data: ShareUpdateInput;
  where: ShareWhereUniqueInput;
};


export type MutationUpsertOneReviewArgs = {
  create: ReviewCreateInput;
  update: ReviewUpdateInput;
  where: ReviewWhereUniqueInput;
};


export type MutationUpsertOneShareArgs = {
  create: ShareCreateInput;
  update: ShareUpdateInput;
  where: ShareWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumReviewExportStatusFilter = {
  equals?: InputMaybe<ReviewExportStatus>;
  in?: InputMaybe<Array<ReviewExportStatus>>;
  not?: InputMaybe<NestedEnumReviewExportStatusFilter>;
  notIn?: InputMaybe<Array<ReviewExportStatus>>;
};

export type NestedEnumReviewRequestStatusFilter = {
  equals?: InputMaybe<ReviewRequestStatus>;
  in?: InputMaybe<Array<ReviewRequestStatus>>;
  not?: InputMaybe<NestedEnumReviewRequestStatusFilter>;
  notIn?: InputMaybe<Array<ReviewRequestStatus>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  review?: Maybe<Review>;
  reviewExport?: Maybe<ReviewExport>;
  reviews: Array<Review>;
  share?: Maybe<Share>;
};


export type QueryReviewArgs = {
  where: ReviewWhereUniqueInput;
};


export type QueryReviewExportArgs = {
  where: ReviewExportWhereUniqueInput;
};


export type QueryReviewsArgs = {
  after?: InputMaybe<ReviewWhereUniqueInput>;
  before?: InputMaybe<ReviewWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryShareArgs = {
  where: ShareWhereUniqueInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Review = {
  __typename?: 'Review';
  archivedAt?: Maybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  labels: Array<Scalars['String']>;
  latestVersion: Scalars['Int'];
  projectKey: Scalars['String'];
  reviewExports: Array<ReviewExport>;
  reviewRequests: Array<ReviewRequest>;
  shares: Array<Share>;
  status: ReviewStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ReviewReviewExportsArgs = {
  after?: InputMaybe<ReviewExportWhereUniqueInput>;
  before?: InputMaybe<ReviewExportWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReviewReviewExportsOrderByInput>>;
  where?: InputMaybe<ReviewReviewExportsWhereInput>;
};

export type ReviewAuthorUserIdProjectKeyCompoundUniqueInput = {
  authorUserId: Scalars['String'];
  projectKey: Scalars['String'];
};

export type ReviewCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<ReviewCreatelabelsInput>;
  latestVersion?: InputMaybe<Scalars['Int']>;
  projectKey: Scalars['String'];
  reviewExports?: InputMaybe<ReviewExportCreateNestedManyWithoutReviewInput>;
  reviewRequests?: InputMaybe<ReviewRequestCreateNestedManyWithoutReviewInput>;
  shares?: InputMaybe<ShareCreateNestedManyWithoutReviewInput>;
  status?: InputMaybe<ReviewStatus>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewCreateNestedOneWithoutReviewExportsInput = {
  connect?: InputMaybe<ReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReviewCreateOrConnectWithoutReviewExportsInput>;
  create?: InputMaybe<ReviewCreateWithoutReviewExportsInput>;
};

export type ReviewCreateNestedOneWithoutReviewRequestsInput = {
  connect?: InputMaybe<ReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReviewCreateOrConnectWithoutReviewRequestsInput>;
  create?: InputMaybe<ReviewCreateWithoutReviewRequestsInput>;
};

export type ReviewCreateNestedOneWithoutSharesInput = {
  connect?: InputMaybe<ReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReviewCreateOrConnectWithoutSharesInput>;
  create?: InputMaybe<ReviewCreateWithoutSharesInput>;
};

export type ReviewCreateOrConnectWithoutReviewExportsInput = {
  create: ReviewCreateWithoutReviewExportsInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateOrConnectWithoutReviewRequestsInput = {
  create: ReviewCreateWithoutReviewRequestsInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateOrConnectWithoutSharesInput = {
  create: ReviewCreateWithoutSharesInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateWithoutReviewExportsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<ReviewCreatelabelsInput>;
  latestVersion?: InputMaybe<Scalars['Int']>;
  projectKey: Scalars['String'];
  reviewRequests?: InputMaybe<ReviewRequestCreateNestedManyWithoutReviewInput>;
  shares?: InputMaybe<ShareCreateNestedManyWithoutReviewInput>;
  status?: InputMaybe<ReviewStatus>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewCreateWithoutReviewRequestsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<ReviewCreatelabelsInput>;
  latestVersion?: InputMaybe<Scalars['Int']>;
  projectKey: Scalars['String'];
  reviewExports?: InputMaybe<ReviewExportCreateNestedManyWithoutReviewInput>;
  shares?: InputMaybe<ShareCreateNestedManyWithoutReviewInput>;
  status?: InputMaybe<ReviewStatus>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewCreateWithoutSharesInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<ReviewCreatelabelsInput>;
  latestVersion?: InputMaybe<Scalars['Int']>;
  projectKey: Scalars['String'];
  reviewExports?: InputMaybe<ReviewExportCreateNestedManyWithoutReviewInput>;
  reviewRequests?: InputMaybe<ReviewRequestCreateNestedManyWithoutReviewInput>;
  status?: InputMaybe<ReviewStatus>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewCreatelabelsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ReviewExport = {
  __typename?: 'ReviewExport';
  createdAt: Scalars['DateTime'];
  downloadUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  masterPreviewUrl?: Maybe<Scalars['String']>;
  masterThumbnailUrl?: Maybe<Scalars['String']>;
  masterUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  review: Review;
  status: ReviewExportStatus;
  thumbnailUrl?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type ReviewExportCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  review: ReviewCreateNestedOneWithoutReviewExportsInput;
  status?: InputMaybe<ReviewExportStatus>;
  version?: InputMaybe<Scalars['Int']>;
};

export type ReviewExportCreateManyReviewInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  status?: InputMaybe<ReviewExportStatus>;
  version?: InputMaybe<Scalars['Int']>;
};

export type ReviewExportCreateManyReviewInputEnvelope = {
  data?: InputMaybe<Array<ReviewExportCreateManyReviewInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReviewExportCreateNestedManyWithoutReviewInput = {
  connect?: InputMaybe<Array<ReviewExportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewExportCreateOrConnectWithoutReviewInput>>;
  create?: InputMaybe<Array<ReviewExportCreateWithoutReviewInput>>;
  createMany?: InputMaybe<ReviewExportCreateManyReviewInputEnvelope>;
};

export type ReviewExportCreateOrConnectWithoutReviewInput = {
  create: ReviewExportCreateWithoutReviewInput;
  where: ReviewExportWhereUniqueInput;
};

export type ReviewExportCreateWithoutReviewInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  status?: InputMaybe<ReviewExportStatus>;
  version?: InputMaybe<Scalars['Int']>;
};

export type ReviewExportReviewIdVersionKeyCompoundUniqueInput = {
  key: Scalars['String'];
  reviewId: Scalars['String'];
  version: Scalars['Int'];
};

export type ReviewExportScalarWhereInput = {
  AND?: InputMaybe<Array<ReviewExportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReviewExportScalarWhereInput>>;
  OR?: InputMaybe<Array<ReviewExportScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  reviewId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumReviewExportStatusFilter>;
  version?: InputMaybe<IntFilter>;
};

export enum ReviewExportStatus {
  Error = 'Error',
  Exporting = 'Exporting',
  Pending = 'Pending',
  Processing = 'Processing',
  Ready = 'Ready'
}

export type ReviewExportUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  review?: InputMaybe<ReviewUpdateOneRequiredWithoutReviewExportsInput>;
  status?: InputMaybe<EnumReviewExportStatusFieldUpdateOperationsInput>;
  version?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReviewExportUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumReviewExportStatusFieldUpdateOperationsInput>;
  version?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReviewExportUpdateManyWithWhereWithoutReviewInput = {
  data: ReviewExportUpdateManyMutationInput;
  where: ReviewExportScalarWhereInput;
};

export type ReviewExportUpdateManyWithoutReviewInput = {
  connect?: InputMaybe<Array<ReviewExportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewExportCreateOrConnectWithoutReviewInput>>;
  create?: InputMaybe<Array<ReviewExportCreateWithoutReviewInput>>;
  createMany?: InputMaybe<ReviewExportCreateManyReviewInputEnvelope>;
  delete?: InputMaybe<Array<ReviewExportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReviewExportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReviewExportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReviewExportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReviewExportUpdateWithWhereUniqueWithoutReviewInput>>;
  updateMany?: InputMaybe<Array<ReviewExportUpdateManyWithWhereWithoutReviewInput>>;
  upsert?: InputMaybe<Array<ReviewExportUpsertWithWhereUniqueWithoutReviewInput>>;
};

export type ReviewExportUpdateWithWhereUniqueWithoutReviewInput = {
  data: ReviewExportUpdateWithoutReviewInput;
  where: ReviewExportWhereUniqueInput;
};

export type ReviewExportUpdateWithoutReviewInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumReviewExportStatusFieldUpdateOperationsInput>;
  version?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReviewExportUpsertWithWhereUniqueWithoutReviewInput = {
  create: ReviewExportCreateWithoutReviewInput;
  update: ReviewExportUpdateWithoutReviewInput;
  where: ReviewExportWhereUniqueInput;
};

export type ReviewExportWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  reviewId_version_key?: InputMaybe<ReviewExportReviewIdVersionKeyCompoundUniqueInput>;
};

export type ReviewRequest = {
  __typename?: 'ReviewRequest';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  review: Review;
  reviewerUser?: Maybe<User>;
  reviewerUserId: Scalars['String'];
  status: ReviewRequestStatus;
};

export type ReviewRequestCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  review: ReviewCreateNestedOneWithoutReviewRequestsInput;
  reviewerUserId: Scalars['String'];
  status?: InputMaybe<ReviewRequestStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewRequestCreateManyReviewInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reviewerUserId: Scalars['String'];
  status?: InputMaybe<ReviewRequestStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewRequestCreateManyReviewInputEnvelope = {
  data?: InputMaybe<Array<ReviewRequestCreateManyReviewInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReviewRequestCreateNestedManyWithoutReviewInput = {
  connect?: InputMaybe<Array<ReviewRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewRequestCreateOrConnectWithoutReviewInput>>;
  create?: InputMaybe<Array<ReviewRequestCreateWithoutReviewInput>>;
  createMany?: InputMaybe<ReviewRequestCreateManyReviewInputEnvelope>;
};

export type ReviewRequestCreateOrConnectWithoutReviewInput = {
  create: ReviewRequestCreateWithoutReviewInput;
  where: ReviewRequestWhereUniqueInput;
};

export type ReviewRequestCreateWithoutReviewInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reviewerUserId: Scalars['String'];
  status?: InputMaybe<ReviewRequestStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewRequestReviewerUserIdReviewIdCompoundUniqueInput = {
  reviewId: Scalars['String'];
  reviewerUserId: Scalars['String'];
};

export type ReviewRequestScalarWhereInput = {
  AND?: InputMaybe<Array<ReviewRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReviewRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<ReviewRequestScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reviewId?: InputMaybe<StringFilter>;
  reviewerUserId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumReviewRequestStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export enum ReviewRequestStatus {
  Approved = 'Approved',
  Comments = 'Comments',
  Pending = 'Pending'
}

export type ReviewRequestUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  review?: InputMaybe<ReviewUpdateOneRequiredWithoutReviewRequestsInput>;
  reviewerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumReviewRequestStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewRequestUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumReviewRequestStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewRequestUpdateManyWithWhereWithoutReviewInput = {
  data: ReviewRequestUpdateManyMutationInput;
  where: ReviewRequestScalarWhereInput;
};

export type ReviewRequestUpdateManyWithoutReviewInput = {
  connect?: InputMaybe<Array<ReviewRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewRequestCreateOrConnectWithoutReviewInput>>;
  create?: InputMaybe<Array<ReviewRequestCreateWithoutReviewInput>>;
  createMany?: InputMaybe<ReviewRequestCreateManyReviewInputEnvelope>;
  delete?: InputMaybe<Array<ReviewRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReviewRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReviewRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<ReviewRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<ReviewRequestUpdateWithWhereUniqueWithoutReviewInput>>;
  updateMany?: InputMaybe<Array<ReviewRequestUpdateManyWithWhereWithoutReviewInput>>;
  upsert?: InputMaybe<Array<ReviewRequestUpsertWithWhereUniqueWithoutReviewInput>>;
};

export type ReviewRequestUpdateWithWhereUniqueWithoutReviewInput = {
  data: ReviewRequestUpdateWithoutReviewInput;
  where: ReviewRequestWhereUniqueInput;
};

export type ReviewRequestUpdateWithoutReviewInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumReviewRequestStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewRequestUpsertWithWhereUniqueWithoutReviewInput = {
  create: ReviewRequestCreateWithoutReviewInput;
  update: ReviewRequestUpdateWithoutReviewInput;
  where: ReviewRequestWhereUniqueInput;
};

export type ReviewRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  reviewerUserId_reviewId?: InputMaybe<ReviewRequestReviewerUserIdReviewIdCompoundUniqueInput>;
};

export type ReviewReviewExportsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type ReviewReviewExportsWhereInput = {
  status?: InputMaybe<EnumReviewExportStatusFilter>;
};

export enum ReviewStatus {
  Approved = 'Approved',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export type ReviewUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  labels?: InputMaybe<ReviewUpdatelabelsInput>;
  latestVersion?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewExports?: InputMaybe<ReviewExportUpdateManyWithoutReviewInput>;
  reviewRequests?: InputMaybe<ReviewRequestUpdateManyWithoutReviewInput>;
  shares?: InputMaybe<ShareUpdateManyWithoutReviewInput>;
  status?: InputMaybe<EnumReviewStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdateOneRequiredWithoutReviewExportsInput = {
  connect?: InputMaybe<ReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReviewCreateOrConnectWithoutReviewExportsInput>;
  create?: InputMaybe<ReviewCreateWithoutReviewExportsInput>;
  update?: InputMaybe<ReviewUpdateWithoutReviewExportsInput>;
  upsert?: InputMaybe<ReviewUpsertWithoutReviewExportsInput>;
};

export type ReviewUpdateOneRequiredWithoutReviewRequestsInput = {
  connect?: InputMaybe<ReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReviewCreateOrConnectWithoutReviewRequestsInput>;
  create?: InputMaybe<ReviewCreateWithoutReviewRequestsInput>;
  update?: InputMaybe<ReviewUpdateWithoutReviewRequestsInput>;
  upsert?: InputMaybe<ReviewUpsertWithoutReviewRequestsInput>;
};

export type ReviewUpdateOneRequiredWithoutSharesInput = {
  connect?: InputMaybe<ReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReviewCreateOrConnectWithoutSharesInput>;
  create?: InputMaybe<ReviewCreateWithoutSharesInput>;
  update?: InputMaybe<ReviewUpdateWithoutSharesInput>;
  upsert?: InputMaybe<ReviewUpsertWithoutSharesInput>;
};

export type ReviewUpdateWithoutReviewExportsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  labels?: InputMaybe<ReviewUpdatelabelsInput>;
  latestVersion?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewRequests?: InputMaybe<ReviewRequestUpdateManyWithoutReviewInput>;
  shares?: InputMaybe<ShareUpdateManyWithoutReviewInput>;
  status?: InputMaybe<EnumReviewStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdateWithoutReviewRequestsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  labels?: InputMaybe<ReviewUpdatelabelsInput>;
  latestVersion?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewExports?: InputMaybe<ReviewExportUpdateManyWithoutReviewInput>;
  shares?: InputMaybe<ShareUpdateManyWithoutReviewInput>;
  status?: InputMaybe<EnumReviewStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdateWithoutSharesInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  labels?: InputMaybe<ReviewUpdatelabelsInput>;
  latestVersion?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewExports?: InputMaybe<ReviewExportUpdateManyWithoutReviewInput>;
  reviewRequests?: InputMaybe<ReviewRequestUpdateManyWithoutReviewInput>;
  status?: InputMaybe<EnumReviewStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdatelabelsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ReviewUpsertWithoutReviewExportsInput = {
  create: ReviewCreateWithoutReviewExportsInput;
  update: ReviewUpdateWithoutReviewExportsInput;
};

export type ReviewUpsertWithoutReviewRequestsInput = {
  create: ReviewCreateWithoutReviewRequestsInput;
  update: ReviewUpdateWithoutReviewRequestsInput;
};

export type ReviewUpsertWithoutSharesInput = {
  create: ReviewCreateWithoutSharesInput;
  update: ReviewUpdateWithoutSharesInput;
};

export type ReviewWhereUniqueInput = {
  authorUserId_projectKey?: InputMaybe<ReviewAuthorUserIdProjectKeyCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type Share = {
  __typename?: 'Share';
  archivedAt?: Maybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  passwordHash?: Maybe<Scalars['String']>;
  review: Review;
  theme?: Maybe<Scalars['Json']>;
};

export type ShareCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  passwordHash?: InputMaybe<Scalars['String']>;
  review: ReviewCreateNestedOneWithoutSharesInput;
  theme?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ShareCreateManyReviewInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  passwordHash?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ShareCreateManyReviewInputEnvelope = {
  data?: InputMaybe<Array<ShareCreateManyReviewInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ShareCreateNestedManyWithoutReviewInput = {
  connect?: InputMaybe<Array<ShareWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShareCreateOrConnectWithoutReviewInput>>;
  create?: InputMaybe<Array<ShareCreateWithoutReviewInput>>;
  createMany?: InputMaybe<ShareCreateManyReviewInputEnvelope>;
};

export type ShareCreateOrConnectWithoutReviewInput = {
  create: ShareCreateWithoutReviewInput;
  where: ShareWhereUniqueInput;
};

export type ShareCreateWithoutReviewInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  authorUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  passwordHash?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ShareReviewIdIsPublicCompoundUniqueInput = {
  isPublic: Scalars['Boolean'];
  reviewId: Scalars['String'];
};

export type ShareScalarWhereInput = {
  AND?: InputMaybe<Array<ShareScalarWhereInput>>;
  NOT?: InputMaybe<Array<ShareScalarWhereInput>>;
  OR?: InputMaybe<Array<ShareScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  authorUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  passwordHash?: InputMaybe<StringNullableFilter>;
  reviewId?: InputMaybe<StringFilter>;
  theme?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ShareUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  review?: InputMaybe<ReviewUpdateOneRequiredWithoutSharesInput>;
  theme?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ShareUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  theme?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ShareUpdateManyWithWhereWithoutReviewInput = {
  data: ShareUpdateManyMutationInput;
  where: ShareScalarWhereInput;
};

export type ShareUpdateManyWithoutReviewInput = {
  connect?: InputMaybe<Array<ShareWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShareCreateOrConnectWithoutReviewInput>>;
  create?: InputMaybe<Array<ShareCreateWithoutReviewInput>>;
  createMany?: InputMaybe<ShareCreateManyReviewInputEnvelope>;
  delete?: InputMaybe<Array<ShareWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ShareScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ShareWhereUniqueInput>>;
  set?: InputMaybe<Array<ShareWhereUniqueInput>>;
  update?: InputMaybe<Array<ShareUpdateWithWhereUniqueWithoutReviewInput>>;
  updateMany?: InputMaybe<Array<ShareUpdateManyWithWhereWithoutReviewInput>>;
  upsert?: InputMaybe<Array<ShareUpsertWithWhereUniqueWithoutReviewInput>>;
};

export type ShareUpdateWithWhereUniqueWithoutReviewInput = {
  data: ShareUpdateWithoutReviewInput;
  where: ShareWhereUniqueInput;
};

export type ShareUpdateWithoutReviewInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  theme?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ShareUpsertWithWhereUniqueWithoutReviewInput = {
  create: ShareCreateWithoutReviewInput;
  update: ShareUpdateWithoutReviewInput;
  where: ShareWhereUniqueInput;
};

export type ShareWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  reviewId_isPublic?: InputMaybe<ShareReviewIdIsPublicCompoundUniqueInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  streamToken?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  /** @deprecated Use "sub" instead */
  user_id?: Maybe<Scalars['String']>;
};

export type CreateReviewMutationVariables = Exact<{
  reviewKey: ReviewAuthorUserIdProjectKeyCompoundUniqueInput;
  create: ReviewCreateInput;
  update: ReviewUpdateInput;
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', review: { __typename?: 'Review', id: string, latestVersion: number, reviewRequests: Array<{ __typename?: 'ReviewRequest', id: string, status: ReviewRequestStatus, createdAt: any, reviewerUser?: { __typename?: 'User', sub?: string | null, nickname?: string | null, name?: string | null, email?: string | null, picture?: string | null } | null }>, shares: Array<{ __typename?: 'Share', id: string, isPublic: boolean }> } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', sub?: string | null, nickname?: string | null, name?: string | null, email?: string | null, picture?: string | null, streamToken?: string | null } | null };

export type ReviewQueryVariables = Exact<{
  where: ReviewAuthorUserIdProjectKeyCompoundUniqueInput;
}>;


export type ReviewQuery = { __typename?: 'Query', review?: { __typename?: 'Review', id: string, reviewRequests: Array<{ __typename?: 'ReviewRequest', status: ReviewRequestStatus, reviewerUserId: string, reviewerUser?: { __typename?: 'User', sub?: string | null, nickname?: string | null, name?: string | null, email?: string | null, picture?: string | null } | null }> } | null };

export type ReviewExportQueryVariables = Exact<{
  where: ReviewExportWhereUniqueInput;
}>;


export type ReviewExportQuery = { __typename?: 'Query', reviewExport?: { __typename?: 'ReviewExport', id: string, key: string, thumbnailUrl?: string | null, previewUrl?: string | null, downloadUrl?: string | null, masterUrl?: string | null, masterThumbnailUrl?: string | null, masterPreviewUrl?: string | null, version: number, createdAt: any, status: ReviewExportStatus, review: { __typename?: 'Review', id: string } } | null };

export type ShareQueryVariables = Exact<{
  where: ShareWhereUniqueInput;
}>;


export type ShareQuery = { __typename?: 'Query', share?: { __typename?: 'Share', id: string, review: { __typename?: 'Review', latestVersion: number, reviewExports: Array<{ __typename?: 'ReviewExport', id: string, key: string, status: ReviewExportStatus, version: number, thumbnailUrl?: string | null, previewUrl?: string | null, downloadUrl?: string | null, masterUrl?: string | null, masterThumbnailUrl?: string | null, masterPreviewUrl?: string | null }>, reviewRequests: Array<{ __typename?: 'ReviewRequest', id: string, reviewerUser?: { __typename?: 'User', email?: string | null } | null }> } } | null };

export type UpdateReviewExportMutationVariables = Exact<{
  where: ReviewExportWhereUniqueInput;
  data: ReviewExportUpdateInput;
}>;


export type UpdateReviewExportMutation = { __typename?: 'Mutation', updateOneReviewExport?: { __typename?: 'ReviewExport', id: string, status: ReviewExportStatus } | null };


export const CreateReviewDocument = gql`
    mutation CreateReview($reviewKey: ReviewAuthorUserIdProjectKeyCompoundUniqueInput!, $create: ReviewCreateInput!, $update: ReviewUpdateInput!) {
  review: upsertOneReview(
    where: {authorUserId_projectKey: $reviewKey}
    update: $update
    create: $create
  ) {
    id
    latestVersion
    reviewRequests {
      id
      reviewerUser {
        sub
        nickname
        name
        email
        picture
      }
      status
      createdAt
    }
    shares {
      id
      isPublic
    }
  }
}
    `;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    sub
    nickname
    name
    email
    picture
    streamToken
  }
}
    `;
export const ReviewDocument = gql`
    query Review($where: ReviewAuthorUserIdProjectKeyCompoundUniqueInput!) {
  review(where: {authorUserId_projectKey: $where}) {
    id
    reviewRequests {
      status
      reviewerUserId
      reviewerUser {
        sub
        nickname
        name
        email
        picture
      }
    }
  }
}
    `;
export const ReviewExportDocument = gql`
    query ReviewExport($where: ReviewExportWhereUniqueInput!) {
  reviewExport(where: $where) {
    id
    key
    thumbnailUrl
    previewUrl
    downloadUrl
    masterUrl
    masterThumbnailUrl
    masterPreviewUrl
    version
    createdAt
    status
    review {
      id
    }
  }
}
    `;
export const ShareDocument = gql`
    query Share($where: ShareWhereUniqueInput!) {
  share(where: $where) {
    id
    review {
      latestVersion
      reviewExports(first: 2, orderBy: [{createdAt: desc}]) {
        id
        key
        status
        version
        thumbnailUrl
        previewUrl
        downloadUrl
        masterUrl
        masterThumbnailUrl
        masterPreviewUrl
      }
      reviewRequests {
        id
        reviewerUser {
          email
        }
      }
    }
  }
}
    `;
export const UpdateReviewExportDocument = gql`
    mutation UpdateReviewExport($where: ReviewExportWhereUniqueInput!, $data: ReviewExportUpdateInput!) {
  updateOneReviewExport(where: $where, data: $data) {
    id
    status
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateReview(variables: CreateReviewMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReviewMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateReviewMutation>(CreateReviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateReview', 'mutation');
    },
    CurrentUser(variables?: CurrentUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CurrentUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CurrentUserQuery>(CurrentUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CurrentUser', 'query');
    },
    Review(variables: ReviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReviewQuery>(ReviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Review', 'query');
    },
    ReviewExport(variables: ReviewExportQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReviewExportQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReviewExportQuery>(ReviewExportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReviewExport', 'query');
    },
    Share(variables: ShareQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShareQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShareQuery>(ShareDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Share', 'query');
    },
    UpdateReviewExport(variables: UpdateReviewExportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateReviewExportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateReviewExportMutation>(UpdateReviewExportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateReviewExport', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;