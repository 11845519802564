import { Share } from "../lib/query";
import queryString from "query-string";

function query(options: Record<string, unknown>): string {
  if (!Object.values(options).length) {
    return "";
  }

  return "?" + queryString.stringify(options);
}

export function appUrl(path = "", options: { relative?: true } = {}): string {
  if (options.relative) {
    return path;
  }

  return (
    (process.env.NEXT_PUBLIC_DABOUNCE_URL || // fallback for PR "preview" environment
      `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`) + path
  );
}

export function apiUrl(path = "", options = {}): string {
  return appUrl("/api" + path, options);
}

export function authUrl(path = "", options = {}): string {
  return apiUrl("/auth" + path, options);
}

export function shareUrl(
  share: Pick<Share, "id">,
  shareOptions: {
    master?: boolean;
    start?: string;
  } = {},
  options: { relative?: true } = {}
): string {
  const path = `/${share.id}` + query(shareOptions);

  if (options.relative) {
    return path;
  }

  return (
    (process.env.NEXT_PUBLIC_DABOUNCE_SHARE_URL || // fallback for PR "preview" environment
      `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`) + path
  );
}
