import { useEffect, useState, useContext, createContext } from "react";
import { UseQueryResult } from "react-query";
import router from "next/router";
// import Cookies from "js-cookie";
import { useCurrentUserQuery, CurrentUserQuery } from "../query";
import queryClient from "../query-client";
import { defaultClient } from "../graphql-client";
import { authUrl } from "../url";

type UserProperties = {
  sub: string;
  nickname: string;
  name: string;
  email: string;
  picture: string;
  updated_at: string;
  created_at: string;
  streamToken: string;
};

export type User = UserProperties & {
  user_id: string;
  email_verified?: boolean;
};

export type CurrentUser = UserProperties & {
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
};

type AuthTokens = {
  id_token: string;
};

export type FetchCurrentUserOptions = {
  required?: true;
  initialValue?: CurrentUser;
};

const AuthTokensContext = createContext<AuthTokens | null>(null);

export function AuthTokensProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const [authTokens, setAuthTokens] = useState<AuthTokens>(null);

  useEffect(() => {
    if (globalThis.app) {
      const unsubscribeCallback = globalThis.app.subscribeAuthTokens(
        async (event, authTokens: AuthTokens) => {
          if (process.env.NODE_ENV === "development") {
            console.log("set-auth-tokens", authTokens);
          } else {
            console.log("set-auth-tokens", authTokens ? "[Filtered]" : null); // never log tokens outside of dev
          }

          if (!authTokens) {
            defaultClient.setHeader("Authorization", "");

            if (localStorage) {
              localStorage.clear();
            }

            queryClient.resetQueries();
          } else {
            defaultClient.setHeader(
              "Authorization",
              `Bearer ${authTokens.id_token}`
            );
          }

          setAuthTokens(authTokens);
        }
      );

      globalThis.app.scheduleRefreshAuthTokens();

      return unsubscribeCallback;
    }
  }, []);

  return (
    <AuthTokensContext.Provider value={authTokens}>
      {props.children}
    </AuthTokensContext.Provider>
  );
}

export function useAuthed(): boolean | void {
  const authTokens = useContext(AuthTokensContext);

  return globalThis.app
    ? authTokens && authTokens.id_token !== undefined
    : true; // Cookies.get("appSession") !== undefined;
}

export function useCurrentUser(
  props: FetchCurrentUserOptions = {}
): UseQueryResult<CurrentUserQuery> {
  const placeholderData =
    props.initialValue !== undefined
      ? { currentUser: props.initialValue }
      : undefined;

  const authed = useAuthed();
  const currentUser = useCurrentUserQuery(defaultClient, undefined, {
    placeholderData,
    queryKey: [authed],
    enabled: Boolean(authed),
  });

  if (
    props.required &&
    !currentUser.data?.currentUser &&
    currentUser.status === "success"
  ) {
    router.router.replace(
      authUrl(
        `/login?${
          globalThis.app
            ? "transfer=1"
            : window.location.pathname + window.location.search
        }`,
        { relative: false } // to fire will-navigate in Electron
      )
    );
  }

  return currentUser;
}
